<template>
  <div>
    <header>
      <div class="navbar navbar-dark bg-dark shadow-sm">
        <div class="container">
          <a href="/" class="navbar-brand d-flex align-items-center">
            <img src="@/assets/logo.png" alt="Logo Taekwondo Indonesia" srcset="" width="52px" />
          </a>
          <div class="text-end" v-if="!currentUser">
            <router-link class="btn btn-light text-dark me-2" :to="{ name: 'login' }">Masuk</router-link>
            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#registerModal">
              Daftar
            </button>
            <!-- <router-link to="/register" class="btn btn-primary"
              >Daftar</router-link
            > -->
          </div>
          <div class="dropdown" v-else>
            <a class="nav-link dropdown-toggle white--text" id="userMenuDropdown" role="button"
              data-bs-toggle="dropdown" aria-expanded="false">
              <img src="https://s3.eu-central-1.amazonaws.com/bootstrapbaymisc/blog/24_days_bootstrap/fox.jpg"
                width="40" height="40" class="rounded-circle" />
            </a>
            <ul class="dropdown-menu" aria-labelledby="userMenuDropdown">
              <li v-if="[role.SuperUser, role.Admin, role.PAC, role.DPC].includes(currentUser.role)">
                <router-link class="dropdown-item" to="/admin">Admin panel</router-link>
              </li>
              <li>
                <router-link class="dropdown-item" to="/change_password">Ganti password</router-link>
              </li>
              <li>
                <button class="dropdown-item" @click="logout">Keluar</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>

    <main>
      <section class="py-5 text-center container">
        <div class="row py-lg-5">
          <div class="col-lg-6 col-md-8 mx-auto">
            <h1 class="display-4 fw-bold">Ganti password</h1>
            <div class="input-group mb-3">
              <input type="password" class="form-control" placeholder="Password saat ini" aria-label="Password saat ini"
                aria-describedby="basic-addon2" v-model="currentPassword"
                :class="{ 'is-invalid': $v.currentPassword.$error }" />
              <div v-if="$v.currentPassword.$error" class="invalid-feedback">
                <span v-if="!$v.currentPassword.required">Harus di isi.</span>
              </div>
            </div>
            <div class="input-group mb-3">
              <input type="password" class="form-control" placeholder="Password baru" aria-label="Password baru"
                aria-describedby="basic-addon2" v-model="newPassword"
                :class="{ 'is-invalid': $v.newPassword.$error }" />
              <div v-if="$v.newPassword.$error" class="invalid-feedback">
                <span v-if="!$v.newPassword.required">Harus di isi.</span>
              </div>
            </div>
            <div class="input-group mb-3">
              <input type="password" class="form-control" placeholder="Password saat ini" aria-label="Password saat ini"
                aria-describedby="basic-addon2" v-model="passwordConfirmation"
                :class="{ 'is-invalid': $v.passwordConfirmation.$error }" />
              <div v-if="$v.passwordConfirmation.$error" class="invalid-feedback">
                <span v-if="!$v.passwordConfirmation.required">Harus di isi.</span>
              </div>
            </div>

            <div class="d-grid gap-2">
              <button class="btn btn-primary" @click="updatePassword" :disabled="isLoading">
                Update Password
              </button>
            </div>

            <div :class="isError ? 'alert alert-danger' : 'alert alert-success'" role="alert" v-if="sended">
              {{ message }}
            </div>
          </div>
        </div>
      </section>
    </main>

    <footer class="text-muted py-5">
      <div class="container">
        <p class="float-end mb-1">
          <a href="#">Kembali ke atas</a>
        </p>
        <p class="mb-1">&copy; 2023 Taekwondo Indonesia</p>
      </div>
    </footer>
  </div>
</template>

<script>
import { authenticationService } from "@/services";
import { required } from "vuelidate/lib/validators";
import { Role } from "@/helpers";
import {
  userService,
} from "@/services";

export default {
  name: "Landing",
  data() {
    return {
      currentPassword: "",
      newPassword: "",
      passwordConfirmation: "",
      sended: false,
      isLoading: false,
      isError: false,
      message: "",
      currentUser: authenticationService.currentUserValue,
      role: Role,
    };
  },
  methods: {
    async updatePassword() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isLoading = true;
        try {
          await userService.updatePassword({ current_password: this.currentPassword, new_password: this.newPassword, password_confirmation: this.passwordConfirmation });

          this.message = "Password berhasil di ubah. Silakan login ulang dengan password yang baru.";
          this.isError = false;
        } catch (error) {
          this.message = error.response.data.message;
          this.isError = true;
        }

        this.sended = true;
        this.isLoading = false;
      }
    },
    logout() {
      authenticationService.logout();
      location.reload();
    },
  },
  validations: {
    currentPassword: {
      required,
    },
    newPassword: {
      required,
    },
    passwordConfirmation: {
      required,
    },
  },
};
</script>

<style scoped>
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}
</style>
